// IIFE called when script is loaded
// Immediately invoked function expression
( function initModule() {
    const carousels = [ ...document.querySelectorAll( '.carousel' ) ];

    carousels.forEach( (carousel) => {
        /*eslint-disable no-undef*/
        carousel = tns({
            container: carousel,
            items: 3,
            slideBy: 'page',
            loop: false,
            rewind: true,
            gutter: 24,
            arrowKeys: true,
            mouseDrag: true,
            controlsText: [
                '<span class="sr-only">Zurück</span><i class="teleicon icon-navigation-left"></i>',
                '<span class="sr-only">Weiter</span><i class="teleicon icon-navigation-right"></i>'
            ],
            responsive: {
                0: {
                    items: 1
                },
                640: {
                    items: 2

                },
                1024: {
                    items: 3
                },
                1500: {
                    items: 3
                }
            }
        });
        /*eslint-enable no-undef*/

        return carousel;
    });
} )();
